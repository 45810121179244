import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'; // Importar ToastContainer y toast
import 'react-toastify/dist/ReactToastify.css';  // Importar estilos CSS de react-toastify
import AppNavbar from './components/Navbar';
import Home from './components/Home';
import Login from './components/Login';
import Statistics from './components/estadisticas/Statics';
import Tickets from './components/tickets/Ticket';
import Nosotros from './components/nosotros/Nosotros';
import Carnets from './components/carnets/Carnets';
import Shop from './components/shop/Shop';
import RRSS from './components/RedesSociales/RRSS';
import Pedidos from './components/pedidos/Pedidos';
import Media from './components/media/Media';
const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [navigate]);

  useEffect(() => {
    if (isAuthenticated && window.location.pathname === '/login') {
      toast.info('Ya estás logueado', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate('/inicio');
    }
  }, [isAuthenticated, navigate]);

  return (
    <div>
      <ToastContainer position="bottom-right" /> {/* Contenedor para las notificaciones */}
      {isAuthenticated && <AppNavbar />}
      <div style={{ marginTop: isAuthenticated ? '50px' : '0', padding: '20px' }}>
        <Routes>
          <Route path="/login" element={!isAuthenticated ? <Login setIsAuthenticated={setIsAuthenticated} /> : <Navigate to="/inicio" />} />
          <Route path="/" element={isAuthenticated ? <Navigate to="/inicio" /> : <Navigate to="/login" />} />
          <Route path="/inicio" element={isAuthenticated ? <Home /> : <Navigate to="/login" />} />
          <Route path="/statics" element={isAuthenticated ? <Statistics /> : <Navigate to="/login" />} />
          <Route path="/tickets" element={isAuthenticated ? <Tickets /> : <Navigate to="/login" />} />
          <Route path="/nosotros" element={isAuthenticated ? <Nosotros /> : <Navigate to="/login" />} />
          <Route path="/carnets" element={isAuthenticated ? <Carnets /> : <Navigate to="/login" />} />
          <Route path="/shop" element={isAuthenticated ? <Shop /> : <Navigate to="/login" />} />
          <Route path="/redes-sociales" element={isAuthenticated ? <RRSS /> : <Navigate to="/login" />} />
          <Route path="/estadisticas" element={isAuthenticated ? <Statistics /> : <Navigate to="/login" />} />
          <Route path="/pedidos" element={isAuthenticated ? <Pedidos /> : <Navigate to="/login" />} />
          <Route path="/media" element={isAuthenticated ? <Media /> : <Navigate to="/login" />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
