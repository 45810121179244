import React from 'react';

const RRSS = () => {
  return (
    <div>
      <h2>Redes Sociales</h2>
      <p>Bienvenido a Gestión Aleron Club.</p>
    </div>
  );
};

export default RRSS;
