import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';

const Estadisticas = () => {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    // Realizar una solicitud GET al endpoint del backend
    axios.get('https://backaleron.aleronclub.com /api/estadisticas/visitas')
      .then(response => {
        const { labels, data } = response.data;
        setChartData({
          labels: labels,
          datasets: [
            {
              label: 'Visitas Diarias',
              data: data,
              borderColor: 'rgba(75,192,192,1)',
              borderWidth: 2,
              fill: false
            }
          ]
        });
      })
      .catch(error => {
        console.error("Hubo un error al obtener los datos: ", error);
      });
  }, []);

  return (
    <div>
      <h2>Estadísticas</h2>
      <div>
        {chartData.labels ? (
          <Line
            data={chartData}
            options={{
              responsive: true,
              title: { text: 'Visitas por Día', display: true },
              scales: {
                y: {
                  beginAtZero: true
                }
              }
            }}
          />
        ) : (
          <p>Cargando datos...</p>
        )}
      </div>
    </div>
  );
};

export default Estadisticas;
