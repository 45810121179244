import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Box } from '@mui/material';

const Home = () => {
  // Estados para almacenar el total de usuarios y los nuevos registros
  const [totalUsuarios, setTotalUsuarios] = useState(0);
  const [nuevosRegistros, setNuevosRegistros] = useState(0);
  const [visitasHoy, setVisitasHoy] = useState(0);
  const [visitasSemanales, setVisitasSemanales] = useState(0);
  const [usuariosOnline, setUsuariosOnline] = useState(0);
  // Función para obtener los datos de la API
  const fetchData = async () => {
    try {
      const responseTotal = await fetch('https://backaleron.aleronclub.com/api/total-contacts');
      const total = await responseTotal.json();
      setTotalUsuarios(total);

      const responseNuevos = await fetch('https://backaleron.aleronclub.com/api/contacts-last-week');
      const nuevos = await responseNuevos.json();
      setNuevosRegistros(nuevos);

      const responseVisitasHoy = await fetch('https://backaleron.aleronclub.com/api/visitas-hoy');
      const visitasHoyData = await responseVisitasHoy.json();
      setVisitasHoy(visitasHoyData);

      const responseVisitasSemanales = await fetch('https://backaleron.aleronclub.com/api/visitas-semanales');
      const visitasSemanalesData = await responseVisitasSemanales.json();
      setVisitasSemanales(visitasSemanalesData);

      const responseUsuariosOnline = await fetch('https://backaleron.aleronclub.com/api/usuarios-online');
      const usuariosOnlineData = await responseUsuariosOnline.json();
      setUsuariosOnline(usuariosOnlineData);

    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <Container maxWidth="lg" className="main-content">
      {/* Logo */}
      <Box display="flex" justifyContent="center" my={4}>
        <img src="./images/LogoAzul.png" alt="Logo Aleron Club" style={{ maxWidth: '200px' }} />
      </Box>

      {/* Cards */}
      <Grid container spacing={4} justifyContent="center" marginTop="4rem">
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Usuarios Activos
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{ marginTop: '1rem' }}>
                Total de usuarios activos:<span style={{ fontWeight: 'bold' }}> {totalUsuarios}</span>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Nuevos Registros
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{ marginTop: '1rem' }}>
                Nuevos registros en la última semana: <span style={{ fontWeight: 'bold' }}>{nuevosRegistros}</span>
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Pedidos totales
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{ marginTop: '1rem' }}>
                Resumen de los pedidos recibidos.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Últimos pedidos
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{ marginTop: '1rem' }}>
                Pedidos totales.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h5">Visitas de Hoy</Typography>
              <Typography variant="body2" color="text.secondary" style={{ marginTop: '1rem' }}>
                Visitas de hoy: <span style={{ fontWeight: 'bold' }}>{visitasHoy}</span>
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h5">Usuarios Online</Typography>
              <Typography variant="body2" color="text.secondary" style={{ marginTop: '1rem' }}>
                Usuarios online: <span style={{ fontWeight: 'bold' }}>{usuariosOnline}</span>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
