import React, { useState, useEffect } from 'react';
import './Carnets.css'; // Asegúrate de usar la ruta correcta según la ubicación del archivo

const Carnets = () => {
  const [carnets, setCarnets] = useState([]);

  // Obtener los carnets de la API
  useEffect(() => {
    fetch('https://backaleron.aleronclub.com/api/carnets') // URL correcta de tu API
      .then(response => response.json())
      .then(data => setCarnets(data))
      .catch(error => console.error('Error al obtener los carnets:', error));
  }, []);

  const handleToggleCreated = (id) => {
    const carnet = carnets.find(c => c.id === id);

    fetch(`https://backaleron.aleronclub.com/api/carnets/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(!carnet.carnetCreado) // Enviar el estado correcto del carnet
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Error al actualizar el estado del carnet');
      }
      return response.json();
    })
    .then(updatedCarnet => {
      setCarnets(carnets.map(c => (c.id === id ? updatedCarnet : c)));
    })
    .catch(error => console.error('Error al actualizar el carnet:', error));
  };

  return (
    <div className="carnets-container"> 
      <h2>Carnets</h2>
      <p>Bienvenido a Gestión Aleron Club.</p>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Imagen</th>
              <th>Nombre</th>
              <th>AKA</th>
              <th>Género</th>
              <th>Ciudad</th>
              <th>Carnet Creado</th>
            </tr>
          </thead>
          <tbody>
            {carnets.map((carnet) => (
              <tr key={carnet.id}>
                <td style={{width: "10%"}}>
                  <img 
                    src={`https://backaleron.aleronclub.com/uploads/${carnet.aka}.png`}  // Construir la URL de la imagen
                    alt={`Perfil de ${carnet.nombre}`} 
                    style={{width: "40%"}}
                    className="perfil-imagen"
                  />
                </td>
                <td>{carnet.nombre}</td>
                <td>{carnet.aka}</td>
                <td>{carnet.genero}</td>
                <td>{carnet.ciudad}</td>
                <td>
                  <span
                    className={`status ${carnet.carnetCreado ? 'si' : 'no'}`}
                  >
                    {carnet.carnetCreado ? 'Sí' : 'No'}
                  </span>
                  <input
                    type="checkbox"
                    checked={carnet.carnetCreado}
                    onChange={() => handleToggleCreated(carnet.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Carnets;
