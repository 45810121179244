import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { toast } from 'react-toastify'; // Importar toast

// Configura el elemento raíz para el modal
Modal.setAppElement('#root');

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
`;

const Title = styled.h1`
  color: #00247D;
  text-align: center;
  margin-bottom: 20px;
`;

const ProductList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
`;

const ProductItem = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: calc(50% - 15px); /* Dos columnas con espacio entre ellas */

  @media (min-width: 768px) {
    width: calc(33.33% - 15px); /* Tres columnas en pantallas más grandes */
  }

  @media (max-width: 768px) {
    width: calc(50% - 15px); /* Dos columnas en pantallas pequeñas */
  }

  @media (max-width: 480px) {
    width: 100%; /* Una columna en pantallas muy pequeñas */
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 150px;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const Info = styled.div`
  flex: 1;
`;

const Actions = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 5px;
  }
`;

const Button = styled.button`
  background-color: #00247D;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.9rem;

  &:hover {
    background-color: #001A59;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const EditModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  margin-top: 200px !important;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  margin: auto;

  h2 {
    margin-bottom: 20px;
  }

  input, select {
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  button {
    margin-top: 10px;
  }
`;

function Shop() {
  const [productos, setProductos] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [editedProduct, setEditedProduct] = useState({});

  useEffect(() => {
    const fetchProductos = async () => {
      try {
        const response = await fetch('https://backaleron.aleronclub.com/api/productos', {
          headers: {
            'Authorization': 'APIAleron2025'
          }
        });
        if (!response.ok) {
          throw new Error('No se pudieron cargar los productos');
        }
        const data = await response.json();
        setProductos(data);
      } catch (error) {
        console.error(error);
        toast.error('Error al cargar los productos');
      }
    };

    fetchProductos();
  }, []);

  const openEditModal = (producto) => {
    setSelectedProduct(producto);
    setEditedProduct(producto);
    setModalIsOpen(true);
  };

  const closeEditModal = () => {
    setModalIsOpen(false);
    setEditedProduct({});
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedProduct({ ...editedProduct, [name]: value });
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`https://backaleron.aleronclub.com/api/productos/${selectedProduct.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'APIAleron2025'
        },
        body: JSON.stringify(editedProduct)
      });

      if (response.ok) {
        toast.success('Producto actualizado');
        // Actualizar la lista de productos
        setProductos(productos.map(p => (p.id === selectedProduct.id ? editedProduct : p)));
        closeEditModal();
      } else {
        throw new Error('Error al actualizar el producto');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al actualizar el producto');
    }
  };

  const handleDelete = (id) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este producto?')) {
      fetch(`https://backaleron.aleronclub.com/api/productos/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': 'APIAleron2025'
        }
      })
        .then(response => {
          if (response.ok) {
            toast.success('Producto eliminado');
            // Actualizar la lista de productos
            setProductos(productos.filter(p => p.id !== id));
          } else {
            throw new Error('Error al eliminar el producto');
          }
        })
        .catch(error => {
          console.error(error);
          toast.error('Error al eliminar el producto');
        });
    }
  };

  return (
    <PageContainer>
      <Title>Gestión de Productos</Title>
      <ProductList>
        {productos.map((producto) => {
          const tallasDisponibles = producto.tallas ? producto.tallas.split(',') : [];
          return (
            <ProductItem key={producto.id}>
              <Image src={producto.imagen} alt={producto.nombre} />
              <Info>
                <h2>{producto.nombre}</h2>
                <p><strong>Precio:</strong> {producto.precio} €</p>
                <p><strong>Stock:</strong> {producto.stock}</p>
                <p><strong>Tallas:</strong> {tallasDisponibles.length > 0 ? tallasDisponibles.join(', ') : 'No disponible'}</p>
              </Info>
              <Actions>
                <Button onClick={() => openEditModal(producto)}>Editar</Button>
                <Button onClick={() => handleDelete(producto.id)}>Eliminar</Button>
              </Actions>
            </ProductItem>
          );
        })}
      </ProductList>

      <EditModal
        isOpen={modalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Editar Producto"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)' // Fondo oscuro para el overlay
          },
          content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 'auto'
          }
        }}
      >
        <h2>Editar Producto</h2>
        {selectedProduct && (
          <>
            <input
              type="text"
              name="nombre"
              value={editedProduct.nombre || ''}
              onChange={handleInputChange}
              placeholder="Nombre del producto"
            />
            <input
              type="number"
              name="precio"
              value={editedProduct.precio || ''}
              onChange={handleInputChange}
              placeholder="Precio"
            />
            <input
              type="number"
              name="stock"
              value={editedProduct.stock || ''}
              onChange={handleInputChange}
              placeholder="Stock"
            />
            <input
              type="text"
              name="tallas"
              value={editedProduct.tallas || ''}
              onChange={handleInputChange}
              placeholder="Tallas (separadas por comas)"
            />
            <div>
              <Button style={{ marginRight: "25px" }} onClick={handleSave}>Guardar</Button>
              <Button onClick={closeEditModal}>Cancelar</Button>
            </div>
          </>
        )}
      </EditModal>
    </PageContainer>
  );
}

export default Shop;
