import React from 'react';

const Tickets = () => {
  return (
    <div>
      <h2>Tickets</h2>
      <p>La gestión integra de los tickets se realiza desde Fourvenues.</p>
    </div>
  );
};

export default Tickets;
