import React from 'react';

const Pedidos = () => {
  return (
    <div>
      <h2>Pedidos</h2>
      <p>Resumen de pedidos</p>
    </div>
  );
};

export default Pedidos;
